import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { accessTokenExpirationGraphqlInterceptor, getAccessToken } from '@cmg/auth';
import { loggerUtil } from '@cmg/common';

// Simple wrapper around window.fetch for use in apollo http link.
const fetcher = window.fetch;

// The URL to the GraphQL API
export const httpLink = createHttpLink({
  uri: '/dlgw/graphql/',
  // Sets httpLink fetch to window.fetch, which datadog sets up its trace proxy on.
  // apollo client httplink must be doing something that is incompatible with how datadog proxies fetch.
  // (more info from a similar tool to datadog: https://docs.logrocket.com/docs/graphql)
  fetch: fetcher,
});

const cache = new InMemoryCache({});

// We'll intercept requests to the GraphQL API, and
// append the authorization token.
export const authLink = setContext((request, previousContext) => {
  const token = getAccessToken();
  return {
    headers: {
      ...previousContext.headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const errorLinkErrorHandler: ErrorHandler = ({ graphQLErrors, networkError }) => {
  const errorLog: string[] = [];
  const graphqlErrorCodes: string[] =
    graphQLErrors?.map(({ extensions }) => extensions?.code) ?? [];

  accessTokenExpirationGraphqlInterceptor(graphqlErrorCodes);

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      errorLog.push(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    errorLog.push(`[Network error]: ${networkError}`);
    console.error(...errorLog);
  }
};

/**
 * onError link is an error interceptor, it gets invoked automatically whenever there is an error
 */
export const errorLink = onError(errorLinkErrorHandler);

/**
 * Retry link is a network error interceptor, it gets invoked automatically whenever there is a network error.
 * It retries the request for a specified number of times.
 * Mitigates the unstable network connection or transient GQL server connection issues.
 */
export const retryLink = new RetryLink({
  delay: {
    initial: 400,
    max: 3000,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    loggerUtil.warning(`Retry attempt #${count} for operation ${operation.operationName}`, error);

    return count < 3;
  },
});

const graphqlApiClient = new ApolloClient({
  link: from([authLink, retryLink, errorLink, httpLink]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default graphqlApiClient;
